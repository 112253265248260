@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One');

* {
	padding: 0;
	margin: 0;
}

body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button:active {
	transform: translateY(4px); 
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.loginTitle {
	margin: 0;
	padding-top: 10px;
	text-align: center;
	font-size: 10vmin;
	color: goldenrod;
	height: 10vh;
	font-family: Alfa SLab One;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: white;
	/* background: linear-gradient(to top, #734800 0%, #E4DB24 49%, #734800 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */

}

.loginTitle img {
	width: 40%;
	padding-top: 20px;
}

.loginForm {
	width: 100%;
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.loginForm {
	color: red;
	font-weight: bold;
}

.column {
	padding-left: 0;
	padding-right: 0;
}

.choose-opponent-col {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-left: 0;
	padding-right: 0;
}

/* .choose-opponent-col img {
	width: 70%;
} */



.opponents-list {
	max-width: 50%;
	display: flex;
	flex-direction: row;
	/* align-items: center; */
	justify-content: center;
}

.avatar-container {
	display: flex;
	flex-direction: column;
    align-items: center;
    /* justify-content: center; */
	margin: 0 4vmin;
}

.avatar-frame-choose-opponent {
	max-width: 20vmin;
	height: 23vmin;
	position: absolute;
	display: flex;
	/* flex-direction: column; */
	align-items: center;
	/* padding-bottom: 3vmin; */
}


.player-image-choose-opponent {
	width: 12.5vmin;
	height: 17.5vmin;
	/* padding-top: 3vmin; */
	margin-top: 2.7vh;
	margin-bottom: 5vh;	
	z-index: 5;
	position: relative;
}

.chooseOpponent-btn {
	font-size: 3vmin;
	font-weight: bold;
	padding: 1vmin 3vmin;
	margin: 5vmin 5vmin ;
	border-radius: 10px;
	background:linear-gradient(180deg,#FFFE01, #FC2000);
}

.choose-opponent-btn-align {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loginFormInput {
	width: 60vmin;
	height: 9vmin;
	font-size: 4vmin;
	font-weight: bold;
	padding-left: 6vmin;
	padding-top: 1.6vmin;
	/* padding-left: 10vmin;
	padding-right: 10vmin;
	margin: 5px auto; */
	background: url(/public/images/input-field.png);
	background-color: transparent;
	background-size: cover;
	background-repeat: no-repeat;
	border: none;
}

.loginForm input {
	width: 48vmin;
	background-color: transparent;
	padding-left: 5px;
	font-weight: bold;
	border: none;

	/* width: 60vmin;
	height: 9vmin;
	font-size: 4vmin;
	font-weight: bold;
	padding-left: 10vmin;
	padding-right: 10vmin;
	margin: 5px auto;
	background: url(/public/images/input-field.png);
	background-color: transparent;
	background-size: cover;
	background-repeat: no-repeat;
	border: none; */
}


/* input:-webkit-autofill {
	color: red;
	background-color: #FC2000;
} */

.loginForm input:focus {
	outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
	-webkit-background-origin: url(/public/images/input-field.png) !important;
    -webkit-box-shadow: 0 0 0 40px #4697CD inset !important;
}

/* .loginForm input:-webkit-autofill#username,
.loginForm input:-webkit-autofill#usernameId_new,
.loginForm input:-webkit-autofill:hover,
.loginForm input:-webkit-autofill:focus,
.loginForm input:-webkit-autofill:active {
-webkit-text-fill-color: #646464 !important;
-webkit-box-shadow: 0 0 0px 1000px #fff inset;
-webkit-padding-start: 8px !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    /* -webkit-box-shadow: 0 0 0 3px green inset !important; */
	background-image:image-set(url(/public/images/input-field.png)) !important;
}



.loginForm input::placeholder {
	color: black;
	font-style: italic;
	font-weight: bold;
	font-family: 'Times New Roman', Times, serif;
}


.loginForm button {
	width: 23%;
	padding: 0;
	border: none;
	font-size: 3vmin;
	background: transparent;
	background-color: transparent;
	border: none;
	margin: 15px auto;
}

.webcontainer {
	width: 100%;
	height: 100vh;
	padding: 0px !important;
	border: 10px solid transparent;
	border-image: linear-gradient(0deg,
			rgba(115, 72, 0, 1) 0%,
			rgba(228, 219, 36, 1) 49%,
			rgba(115, 72, 0, 1) 100%);
	border-image-slice: 1;
	border-radius: 10px;
	background-size: cover;
	background-image: url(/public/images/background.jpg);
}


.mobContainer {
	width: 100%;
	height: 92.5vh;
	overflow: hidden;
	border: 10px solid transparent;
	border-image: linear-gradient(0deg,
			rgba(115, 72, 0, 1) 0%,
			rgba(228, 219, 36, 1) 49%,
			rgba(115, 72, 0, 1) 100%);
	border-image-slice: 1;
	border-radius: 10px;
	background-size: cover;
	background-image: url(/public/images/background.jpg);
}

.alignCenter {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container h2 {
	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	.player-image-choose-opponent {
		width: 12vmin;
		height: 17vmin;
		/* padding-top: 3vmin; */
		margin-top: 3vmin;
		margin-bottom: 7vmin;	
		z-index: 5;
		position: relative;
	}
}