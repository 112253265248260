@import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One");

.mainTitle {
  width: 120%;
  text-align: center;
  /* margin-top: 20px; */
  margin: 0px -30px 20px auto;
  padding: 0px 20px;
  color: goldenrod;
  font-size: 3.5vmin;
  font-family: Alfa Slab One;
  font-style: italic;
  letter-spacing: 1px;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

.logout-btn {
  width: 10vmin;
  height: 4vh;
  margin-top: 2.5vh;
  font-weight: bold;
  font-size: 2vmin;
  border-radius: 10px;
  background: linear-gradient(180deg, #efe2ce, goldenrod);
}

.quitBtn {
  margin-top: -5 !important;
  color: white !important;
}

.menuBg {
  width: 100vw;
  height: 100vh;
  background-image: url("/public/images/menu-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.menu-title {
  width: 100vw;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2vh;
  color: linear;
}

.menu-buttons button {
  width: 20vw;
  padding: 10px 20px;
  margin: 15px auto;
  font-size: 4vmin;
  font-style: italic;
  -webkit-text-stroke: 1.5px black;
  font-family: Alfa Slab One;
  background-color: #905d0d;
  color: #feb300;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.201), 0 0 10px rgba(0, 0, 0, 0.215);
  box-shadow: inset 1px 1px 2px 10px #fcff9e;
  border: 5px double #ae0c03;
  border-image: -webkit-linear-gradient(90deg, #6f4605, #fffe30, #905d0d) 20
    stretch;
}

.activeContainerPadding {
  padding: 15px;
}

.inactiveContainerPadding {
  padding: 5px;
}

.score-vs-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -5vmin;
  text-align: center;
}

.vs {
  margin-top: 7%;
  color: goldenrod;
  font-size: 5vmin;
  font-style: italic;
  font-family: Alfa Slab One;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

.round {
  text-align: center;
  color: goldenrod;
  font-size: 4vmin;
  font-style: italic;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

.title {
  text-align: center;
  color: goldenrod;
  font-size: 4vmin;
  font-style: italic;
  font-family: Alfa Slab One;
  margin: 20px auto 30px auto;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

.score {
  font-size: 2vmin;
  font-style: italic;
  color: goldenrod;
  text-shadow: 0 0 10px black, 0 0 10px black;
}

.players {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px auto 0 auto;
}

.playerInfo {
  width: 100%;
  text-align: center;
  color: goldenrod;
}

.playerInfo h6 {
  margin-top: 5px;
  font-size: 2vmin;
}

.avatar-frame {
  max-width: 10vmin;
  width: 100%;
  height: auto;
  position: absolute;
}

.player-image {
  width: 9vmin;
  height: 9vmin;
  margin-top: 1vmin;
}

.player-win {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../public/images/player-win-bg.jpg);
}

.player-win-container {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.player-win-box {
  width: 40%;
  top: 0;
  margin-bottom: 3vmin;
  z-index: 1;
  position: relative;
}

.player-lose-box {
  width: 85%;
  top: 0;
  /* margin-bottom: 1vmin; */
  z-index: 1;
  position: relative;
}

.player-win-btn {
  margin-top: 19%;
  margin-right: 50px;
  z-index: 15;
  width: 10%;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.player-win-btn-menu {
  width: 100%;
  margin-bottom: 3vmin;
}

.player-win-btn-rematch {
  width: 100%;
  margin-bottom: 3vmin;
}

.player-win-btn-leaderboard {
  width: 100%;
  margin-bottom: 3vmin;
}

.selectCategory {
  width: 100%;
  height: 100vh;
  background: url(../../public/images/choose-player-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.chooseOpponent {
  width: 100%;
  height: 100vh;
  background: url(../../public/images/choose-player-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.category-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.categoriesList {
  height: 370px;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.changeCursor:hover {
  cursor: pointer;
}

.categoryborder {
  border-image: linear-gradient(
      29deg,
      rgba(115, 72, 0, 1) 0%,
      rgb(228, 219, 36, 1) 49%,
      rgba(115, 72, 0, 1) 100%
    )
    1;
  /* border-image: linear-gradient(to left, rgba(115, 72, 0, 1), rgba(228, 219, 36, 1)); */
  border-width: 5px;
  border-radius: 5px !important;
  border-style: solid;
  /* border-image: linear-gradient(to bottom,#E5D218,#E5C306,#B07700,#945D00)1 100% ; */
}

.categoryImg {
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 15px;
  /* height: 15px; */
  height: auto;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  opacity: 0.3;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    0deg,
    rgba(115, 72, 0, 1) 0%,
    rgba(228, 219, 36, 1) 49%,
    rgba(115, 72, 0, 1) 100%
  );
  /* outline: 2px solid #FFF; */
  outline-offset: -2px;
  /* border: .1px solid #B7B7B7; */
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}

::-webkit-scrollbar-button:single-button {
  width: 20px;
  height: 15px;
  background-color: #ad9413;
  display: block;
  /* border-style: solid; */
  border-radius: 50%;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  /* width: 25px;
  height: 20px; */
  /* background-position: 3px 3px; */
  background-image: url(../../public/images/arrow-down.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  /* width: 25px;
  height: 20px; */
  /* background-position: 3px 3px; */
  background-image: url(../../public/images/arrow-up.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 20px;
  /* background-position: 3px 3px; */
  background-image: url(../../public/images/arrow-next.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 20px;
  /* background-position: 3px 3px; */
  background-image: url(../../public/images/arrow-previous.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.card-slot-container {
  /* border: 1px solid black; */
  /* max-width: 1000px;
	width: 83vmin; */
  /* height: 200px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  /* margin-left: -1.5vmin; */
}
.lastCardSlotPaddingRight:last-child {
  padding-right: 10px !important;
}

.card-slot-sub-container {
  /* border: 1px solid black; */
  max-width: 560px;
  /* width: 100%; */
  height: 26vh;
  /* margin-left: -5vmin; */
  padding-bottom: 10px;
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* text-align: center; */
  margin: 0 auto;
  overflow: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.cardSlotWrapper {
  max-width: 11.5vmin;
  width: 100%;
  margin: 0 0.8vmin;
  white-space: pre-line;
}

.card-slot {
  /* width: 30vw; */
  /* max-height: 100%; */
  /* padding-left: 130px;
    border: 2px solid black;
    overflow: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap; */
  display: flex;
  justify-content: center;
}

/* .card-border {
    width: 90px;
    height: auto;
    margin: 0 5px;
} */

.flip-box {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-box-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

.cardContainer {
  max-width: 14vmin;
  width: 90%;
}

.card {
  max-width: 14vmin;
  min-width: 12vmin;
  width: 95%;
  height: 15vh;
  max-height: 16vmin;
  margin: 0 auto;
  /* padding: 0 5px; */
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.2);
  cursor: -webkit-grab;
  cursor: grab;
  /* transform: rotateY(180deg); */
}

.cardModal .modal-content {
  background-color: rgba(15, 4, 4, 0.4);
}

.modal.fade.show {
  backdrop-filter: blur(10px);
}

.card:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.card-date {
  margin-bottom: 5px;
  color: goldenrod;
  text-align: center;
  font-weight: bold;
  font-size: 1.8vmin;
  /* padding-right: 5%; */
}

.card-name {
  margin-top: 5%;
  margin-bottom: 0;
  color: goldenrod;
  text-align: center;
  font-weight: bold;
  font-size: 1.5vmin;
  white-space: pre-line;
  /* padding-right: 5%; */
}

.card-Slot {
  max-width: 7.4%;
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: absolute;
  z-index: 20;
}

.cardSlotHidden {
  max-width: 7%;
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: absolute;
  z-index: 0;
}

.controll-zIndex {
  z-index: 0 !important;
}

.cardSlot {
  max-width: 20vmin;
  min-width: 12vmin;
  /* 	width: 100%; */
  height: 15vh;
  max-height: 16vmin;
  margin: 0 auto;
  background-color: transparent;
  /* padding: 0 5px; */
  z-index: 80;
}

.cardSlotBack {
  animation: flipX 0.5s linear;
}

@keyframes flipX {
  100% {
    transform: rotateY(180deg);
  }
}

.cardSlotflip {
  animation: flipV 0.5s linear;
}

@keyframes flipV {
  100% {
    transform: rotateY(180deg);
  }
}

.top {
  margin-top: 15px;
}

.bottom {
  margin-bottom: 14px;
}

.roundOneOverContainer {
  width: 100%;
  height: 100vh;
  border: 10px solid transparent;
  border-image: linear-gradient(
    0deg,
    rgba(115, 72, 0, 1) 0%,
    rgba(228, 219, 36, 1) 49%,
    rgba(115, 72, 0, 1) 100%
  );
  border-image-slice: 1;
  border-radius: 10px;
  background-size: cover;
  background-image: url(/public/images/round-one-over.jpg);
}

.roundTwoOverContainer {
  width: 100%;
  height: 100vh;
  border: 10px solid transparent;
  border-image: linear-gradient(
    0deg,
    rgba(115, 72, 0, 1) 0%,
    rgba(228, 219, 36, 1) 49%,
    rgba(115, 72, 0, 1) 100%
  );
  border-image-slice: 1;
  border-radius: 10px;
  background-size: cover;
  background-image: url(/public/images/round-two-over.jpg);
}

.roundOneOverInnerContent {
  display: flex;
  flex-direction: column;
}

.roundOverBox {
  text-align: center;
  background-color: rgb(40, 64, 134, 0.7);
}

.rounOverButtons {
  color: goldenrod;
  background-color: #905d0d;
  border: 5px double #ae0c03;
  border-image: -webkit-linear-gradient(90deg, #6f4605, #fffe30, #905d0d) 20
    stretch;
}

.leaderboardContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("/public/images/leaderboard-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.leaderboardBox {
  width: 45%;
  height: 100vh;
  padding-top: 15vh;
  margin-top: -5vh;
  background: url("../../public/images/Leaderboard-box.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
}

.leadboardAlign {
  width: 90%;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}

.leaderboardDisplayCoin {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.leaderboardDisplayCoin p {
  width: 20vmin;
  /* padding: 5px; */
  font-weight: bold;
  background: linear-gradient(180deg, #f0f036 0%, #f15916 100%);
  border: 3px solid #ece1cd;
  border-radius: 30px;
  text-align: center;
  /* padding: ; */
}

.leaderboardCategories {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leaderboardCategoryItem {
  width: 25%;
  padding: 20px;
  text-align: center;
  font-size: 2vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboardCategoryItem :hover {
  cursor: pointer;
}

.leaderboardCategoryItem p {
  width: fit-content;
  font-weight: bold;
  background: linear-gradient(180deg, #f0f036 0%, #f15916 100%);
  border: 3px solid #ece1cd;
  border-radius: 30px;
  margin: 0 20px;
  padding: 0 8px;
}

.leadboardHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d0d02;
}
.leadboardHeader p {
  font-size: 50px;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* .leaderboardCategoryItem p::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px; 
  padding: 3px; 
  background:linear-gradient(180deg,#EFE2CE,#A57626); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
} */

.leaderboardScores {
  width: 80%;
  height: 53vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  /* justify-content: center; */
}

.leaderboardScoreItem {
  width: 80%;
  height: 7vh;
  margin: 10px auto;
  background-color: #b42409;
  border-radius: 50px;
  position: relative;
  /* border-image: linear-gradient(0deg,
			rgba(115, 72, 0, 1) 0%,
			rgba(228, 219, 36, 1) 49%,
			rgba(115, 72, 0, 1) 100%) 1; */

  display: flex;
  align-items: center;
}

.leaderboardScoreItemPoints {
  width: 100%;
  height: 7vh;
  margin: 10px auto;
  background-color: #b42409;
  border-radius: 50px;
  position: relative;
  /* border-image: linear-gradient(0deg,
			rgba(115, 72, 0, 1) 0%,
			rgba(228, 219, 36, 1) 49%,
			rgba(115, 72, 0, 1) 100%) 1; */

  display: flex;
  align-items: center;
}

.leaderboardScoreItemPoints::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 5px;
  background: linear-gradient(180deg, #efe2ce, #a57626);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.leaderboardScoreItem::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 5px;
  background: linear-gradient(180deg, #efe2ce, #a57626);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.leaderboardScoreCount {
  width: 10vw;
  text-align: center;
  font-size: 4.5vh;
  color: white;
  position: relative;
  margin-right: 10px;
  /* border-top: 5px solid #A47725;
	border-bottom: 5px solid #A47725;
	border-right: 5px solid #A47725; */

  /* border-image: linear-gradient(0deg,
			rgba(115, 72, 0, 1) 0%,
			rgba(228, 219, 36, 1) 49%,
			rgba(115, 72, 0, 1) 100%) 1; */
}

.leaderboardScoreCount::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  padding: 5px;
  background: linear-gradient(180deg, #efe2ce, #a57626);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.leaderboardScoreCountPoints {
  width: 14vw;
  text-align: center;
  font-size: 4.5vh;
  color: white;
  position: relative;
  margin-right: 10px;
}

.leaderboardScoreCountPoints::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  padding: 5px;
  background: linear-gradient(180deg, #efe2ce, #a57626);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.leaderboardScoreContentPoints {
  width: 80%;
  text-align: center;
  color: white;
  font-size: larger;
  /* padding-right: 2vw; */
}

.leaderboardScoreContent {
  width: 80%;
  text-align: center;
  color: white;
  font-size: larger;
  padding-right: 2vw;
}

.leaderboadr-btn {
  padding: 0vmin 12vmin;
  margin-top: 85vmin;
  font-size: 6vmin;
  letter-spacing: 5px;
  color: white;
  background: linear-gradient(180deg, #f0f036 0%, #f15916 100%);
  border: 5px solid #db3b19;
  border-radius: 100px;
  position: relative;
  z-index: 5;
}

@media screen and (max-width: 600px) {
  .logout-btn {
    width: 15vmin;
  }

  .player-win-box {
    width: 90%;
  }

  .player-win-btn {
    width: 15%;
    margin-top: 25%;
    margin-right: 30px;
  }
  .player-win-btn-menu {
    margin-bottom: -10px;
  }

  .player-win-btn-rematch {
    margin-bottom: -10px;
    margin-top: -10px;
  }

  /* .player-win-btn-leaderboard {
		margin-top: -10px;
	} */

  .leaderboardBox {
    width: 90vw;
  }

  .leadboardAlign {
    height: 35vh;
    margin: 13vh auto;
  }

  .leaderboardScores {
    width: 100%;
    height: 25vh;
  }

  /* .leaderboardScoreItem {
    width: 90%;
  } */

  .leaderboardScoreCount {
    width: 10rem;
  }

  .leaderboardScoreContent {
    font-size: 15px;
  }

  .menu-buttons button {
    width: 50vw;
  }

  .menu-buttons button {
    width: 45vw;
  }

  .card-slot-sub-container {
    /* width: 120%; */
  }

  .cardContainer {
    max-width: 18vmin;
    margin-left: 0.8vmin;
    margin-right: 0.8vmin;
  }

  .card {
    max-width: 17vmin;
    max-height: 23vmin;
    min-width: 17vmin;
    width: 90%;
    height: 23vmin;
  }

  .cardSlot {
    min-width: 15vmin;
    max-height: 19vmin;
  }

  .categoryImg {
    height: 45vw;
  }
}

@media screen and (min-width: 600px) {
  .player-win-box {
    width: 80%;
  }

  .player-win-btn {
    margin-top: 25%;
  }

  .player-win-btn button {
    margin-bottom: -10px;
  }

  .menu-buttons button {
    width: 45vw;
  }

  .leaderboardBox {
    width: 90%;
  }

  .leaderboardScoreCount {
    width: 10rem;
  }
}

@media screen and (min-width: 768px) {
  .menu-buttons button {
    width: 35vw;
  }

  .leaderboardBox {
    width: 72%;
  }
}

@media screen and (min-width: 992px) {
  .menu-buttons button {
    width: 35vw;
  }

  .leaderboardBox {
    width: 45%;
  }

  .player-win-box {
    width: 60%;
  }

  .player-win-btn {
    margin-top: 19%;
  }
}

@media screen and (min-width: 1200px) {
  /* .leaderboardBox {
		width: 75vw;
	} */

  .menu-buttons button {
    width: 20vw;
  }
}
